import React from "react"
import tocbot from "tocbot"
import Prism from "prismjs"

import Layout from "@src/components/Layout"
import Callout from "@src/components/Callout"
import PostCard from "./components/PostCard"
import SupportFooter from "./components/SupportFooter"
import Row from "../components/Row"

import * as styles from "./BlogPost.module.scss"
import { Link } from "gatsby"

const BlogPost = ({ location, pageContext }) => {
  const baseUrl =
    process.env.NODE_ENV === "staging"
      ? "https://staging.canonic.dev"
      : "https://canonic.dev"

  const [tocFixed, setTocFixed] = React.useState(false)
  const tocRef = React.useRef()
  const contentRef = React.useRef()
  const {
    title,
    custom_excerpt,
    html,
    primary_author,
    feature_image,
    reading_time,
    published_at,
    featured,
    tags,
  } = pageContext?.post || {}

  // React.useEffect(() => {
  //   tocbot.init({
  //     tocElement: tocRef.current,
  //     contentSelector: `.${styles.rowContent}`,
  //     headingSelector: "h1, h2",
  //   })
  // }, [])

  // React.useEffect(() => {
  //   const contentRect = contentRef.current.getBoundingClientRect()
  //   const tocTop = contentRect.top + document.documentElement.scrollTop - 90
  //   const tocBottom = tocTop + contentRect.height - 90
  //   const handleScroll = e => {
  //     if (document.documentElement.scrollTop >= tocTop) setTocFixed(true)
  //     else setTocFixed(false)
  //     if (document.documentElement.scrollTop >= tocBottom) setTocFixed(false)
  //   }
  //   document.addEventListener("scroll", handleScroll)
  //   return () => document.removeEventListener("scroll", handleScroll)
  // }, [])

  React.useLayoutEffect(() => {
    ;[...contentRef.current.querySelectorAll("pre")].forEach(node => {
      console.log(node, [...node.classList].length)
      if ([...node.classList].length === 0)
        node.classList.add("language-javascript")
    })
    Prism.highlightAll()
  }, [html])

  return (
    <Layout
      title={title}
      description={custom_excerpt}
      url={`${baseUrl}/blog/${pageContext?.post?.slug}`}
      location={location}
      ogImage={`${baseUrl}${feature_image}`}
      ogType="article"
      ogTitle={title}
    >
      <div className={styles.layout}>
        <Row className={styles.headerWrapper}>
          <div className={styles.header}>
            <img className={styles.coverImage} src={feature_image} />
            <p className={styles.headerBreadcrumbs}>
              <Link to="/blog">Blog</Link> /{" "}
              {featured ? "🔥 Featured" : tags?.[0]?.name}
            </p>
            <h1 className={styles.headerTitle}>{title}</h1>
            <p className={styles.headerSubtitle}>{custom_excerpt}</p>
            <div className={styles.author}>
              <img
                src={primary_author.profile_image}
                className={styles.authorImage}
              />
              <div className={styles.authorDetails}>
                <h4 className={styles.authorName}>{primary_author.name}</h4>
                <p className={styles.authorDate}>
                  {new Date(published_at).toDateString()} • {reading_time} min
                  read
                </p>
              </div>
            </div>
          </div>
        </Row>
        <Row className={styles.row}>
          {/* <div className={`${styles.toc} ${tocFixed ? styles.fixed : ""}`}>
          <div className={styles.tocTitle}>On this page:</div>
          <div ref={tocRef} className={styles.tocBody} />
        </div> */}
          <section ref={contentRef} className={styles.rowContent}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </section>
          <SupportFooter title={title} />
        </Row>
        <Row className={styles.relatedPostsWrapper}>
          <ul className={`${styles.relatedPosts} ${styles.grid}`}>
            {pageContext?.relatedPosts?.map(post => (
              <PostCard post={post} />
            ))}
          </ul>
        </Row>
        <Callout black />
      </div>
    </Layout>
  )
}

export default BlogPost
