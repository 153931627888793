// extracted by mini-css-extract-plugin
export var active = "SupportFooter-module--active--2Msqo";
export var docSupport = "SupportFooter-module--docSupport--2zeFN";
export var docSupportDiscord = "SupportFooter-module--docSupportDiscord--3GeNY";
export var docSupportFeedback = "SupportFooter-module--docSupportFeedback--dB0Bq";
export var docSupportFeedbackOverlay = "SupportFooter-module--docSupportFeedbackOverlay--2hFve";
export var docSupportFeedbackOverlayFail = "SupportFooter-module--docSupportFeedbackOverlayFail--2ztc-";
export var docSupportFeedbackOverlayFailInput = "SupportFooter-module--docSupportFeedbackOverlayFailInput--3g0RV";
export var docSupportFeedbackOverlaySuccess = "SupportFooter-module--docSupportFeedbackOverlaySuccess--4KYVF";
export var docSupportFeedbackVotes = "SupportFooter-module--docSupportFeedbackVotes--1m63L";
export var docSupportFeedbackVotesDown = "SupportFooter-module--docSupportFeedbackVotesDown--3g7Km";
export var docSupportFeedbackVotesUp = "SupportFooter-module--docSupportFeedbackVotesUp--2icVC";
export var showIn = "SupportFooter-module--show-in--3Rkd4";