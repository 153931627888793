import React from "react"
import * as styles from "./SupportFooter.module.scss"

export default function SupportFooter({ title }) {
  const tracker = React.useRef()
  const [voted, setVoted] = React.useState(false)
  const [state, setState] = React.useState("INITIAL")
  const [feedback, setFeedback] = React.useState("")
  const votedClassName = voted ? styles.active : ""
  const track = React.useCallback((action, value) => {
    tracker.current?.send(
      "event",
      "feedback",
      action,
      value ? `${title}:${value}` : title
    )
  }, [])

  const handleVoteUp = React.useCallback(() => {
    setVoted(1)
    setState("THANK_YOU")
    track("upvote")
  }, [])

  const handleVoteDown = React.useCallback(() => {
    setVoted(-1)
    setState("FEEDBACK")
    track("downvote")
  }, [])

  const handleSubmit = React.useCallback(() => {
    setVoted(-1)
    setState("THANK_YOU")
    track("comments", feedback)
  }, [])

  React.useEffect(() => {
    const handleKey = e => {
      if (e.keyCode === 13) {
        handleSubmit()
        e.preventDefault()
        e.stopPropagation()
      }
    }
    document.addEventListener("keypress", handleKey)
    return () => document.removeEventListener("keypress", handleKey)
  }, [handleSubmit])

  React.useEffect(() => {
    if (localStorage[title]) {
      setState("THANK_YOU")
      setVoted(localStorage[title])
    }

    if (!window[window["GoogleAnalyticsObject"]]) return
    window[window["GoogleAnalyticsObject"]](t => {
      tracker.current = t
    })
  }, [])

  React.useEffect(() => {
    if (state === "THANK_YOU") localStorage.setItem(title, `${voted}`)
  }, [state])

  return (
    <div className={styles.docSupport}>
      <div className={styles.docSupportFeedback}>
        <div className={styles.docSupportFeedbackTitle}>
          Did you find what you were looking for?
        </div>
        <div className={styles.docSupportFeedbackVotes}>
          <div
            onClick={handleVoteUp}
            className={styles.docSupportFeedbackVotesUp}
          >
            👍
          </div>
          <div
            onClick={handleVoteDown}
            className={styles.docSupportFeedbackVotesDown}
          >
            👎
          </div>
        </div>
        <div
          className={`${styles.docSupportFeedbackOverlay} ${votedClassName}`}
        >
          {state === "THANK_YOU" ? (
            <div className={styles.docSupportFeedbackOverlaySuccess}>
              ❤️ <br /> Thank you for your kind feedback!
            </div>
          ) : (
            <div className={styles.docSupportFeedbackOverlayFail}>
              <div className={styles.docSupportFeedbackOverlayFailLabel}>
                What went wrong?
              </div>
              <textarea
                value={feedback}
                key={state}
                autoFocus={state === "FEEDBACK"}
                onChange={e => setFeedback(e.target.value)}
                placeholder="Type summary here..."
                className={styles.docSupportFeedbackOverlayFailInput}
              />
              <button onClick={handleSubmit}>Submit</button>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => window.open("https://discord.gg/9dyytsM", "_blank")}
        className={styles.docSupportDiscord}
      >
        <em>Want to discuss?</em>
        We have a thriving{" "}
        <strong>
          <DiscordIcon />
          Discord
        </strong>
        community that can help you. →
      </div>
    </div>
  )
}

const DiscordIcon = () => (
  <svg
    width="84"
    height="95"
    viewBox="0 0 84 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.3355 39.5225C47.6278 39.5225 45.4901 41.8501 45.4901 44.7478C45.4901 47.6455 47.6753 49.9732 50.3355 49.9732C53.0432 49.9732 55.1808 47.6455 55.1808 44.7478C55.1808 41.8501 52.9956 39.5225 50.3355 39.5225ZM32.9968 39.5225C30.2891 39.5225 28.1515 41.8501 28.1515 44.7478C28.1515 47.6455 30.3366 49.9732 32.9968 49.9732C35.7045 49.9732 37.8421 47.6455 37.8421 44.7478C37.8896 41.8501 35.7045 39.5225 32.9968 39.5225Z"
      fill="#4E61DA"
    />
    <path
      d="M73.422 0H9.76775C4.3999 0 0.0296021 4.37029 0.0296021 9.73815V73.3924C0.0296021 78.7603 4.3999 83.1305 9.76775 83.1305H63.6364L61.1187 74.4375L67.1991 80.0428L72.947 85.3157L83.2077 94.1988V9.73815C83.1602 4.37029 78.7899 0 73.422 0ZM55.0858 61.5166C55.0858 61.5166 53.3757 59.474 51.9506 57.7163C58.1735 55.9587 60.5487 52.111 60.5487 52.111C58.601 53.3936 56.7484 54.2961 55.0858 54.9137C52.7106 55.9112 50.4305 56.5288 48.1978 56.9563C43.6375 57.8114 39.4572 57.5738 35.8945 56.9088C33.1868 56.3863 30.8592 55.6737 28.9115 54.8662C27.819 54.4386 26.6314 53.9161 25.4438 53.251C25.3013 53.156 25.1588 53.1085 25.0163 53.0135C24.9213 52.966 24.8738 52.9185 24.8263 52.9185C23.9712 52.4435 23.4962 52.111 23.4962 52.111C23.4962 52.111 25.7763 55.8637 31.8092 57.6688C30.3841 59.4739 28.6265 61.5641 28.6265 61.5641C18.1283 61.2316 14.138 54.3911 14.138 54.3911C14.138 39.2376 20.9785 26.9343 20.9785 26.9343C27.819 21.8514 34.2794 21.9939 34.2794 21.9939L34.7544 22.564C26.2038 24.9866 22.3086 28.7394 22.3086 28.7394C22.3086 28.7394 23.3537 28.1694 25.1113 27.4093C30.1941 25.1767 34.2319 24.6066 35.8945 24.4166C36.1795 24.3691 36.417 24.3216 36.702 24.3216C39.5997 23.9416 42.8775 23.8466 46.2977 24.2266C50.8105 24.7491 55.6558 26.0792 60.5961 28.7394C60.5961 28.7394 56.8434 25.1766 48.7679 22.754L49.4329 21.9939C49.4329 21.9939 55.9408 21.8514 62.7338 26.9343C62.7338 26.9343 69.5742 39.2376 69.5742 54.3911C69.5742 54.3436 65.584 61.184 55.0858 61.5166V61.5166Z"
      fill="#4E61DA"
    />
  </svg>
)
